import React, {useEffect, useState} from 'react';
import {Button, Container, Nav, Navbar, Offcanvas} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {FaUser, FaUserCircle} from 'react-icons/fa';

const NavBarComponent: React.FC<{
    isAuthenticated: boolean,
    userName: string,
    handleLogin: () => void,
    handleLogout: () => void
}> = ({isAuthenticated, userName, handleLogin, handleLogout}) => {
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const breakpoint = 600;

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < breakpoint);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleCloseOffcanvas = () => setShowOffcanvas(false);
    const handleShowOffcanvas = () => setShowOffcanvas(true);

    return (
        <>
            <Navbar bg="light" sticky="top" className="mb-4">
                <Container className="position-relative">
                    {isMobile ? (
                        <>
                            <div className="d-flex align-items-center justify-content-center w-100 position-relative">
                                <Button
                                    variant="secondary"
                                    onClick={handleShowOffcanvas}
                                    className="me-3 position-absolute start-0"
                                    style={{ fontSize: '1.rem' }}
                                >
                                    ☰
                                </Button>
                                <Navbar.Brand as={Link} to="/">KMBO-04-22</Navbar.Brand>
                            </div>
                        </>
                    ) : (
                        <Navbar.Brand as={Link} to="/">KMBO-04-22</Navbar.Brand>
                    )}

                    {!isMobile && (
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/subjects">Предметы</Nav.Link>
                            <Nav.Link as={Link} to="/contacts">Контакты</Nav.Link>
                        </Nav>
                    )}

                    <Nav className="ms-auto">
                        {isAuthenticated ? (
                            <FaUserCircle size={24} className="me-2"/>
                        ) : (
                            <FaUser size={24} className="me-2"/>
                        )}
                    </Nav>
                </Container>
            </Navbar>

            {isMobile && (
                <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="start" className="bg-light">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title style={{ fontWeight: 'bold', fontSize: '1.5rem', color: '#333' }}>
                            Меню
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="p-4" style={{ background: 'linear-gradient(135deg, #f8f9fa, #e0e0e0)' }}>
                        <Nav className="flex-column">
                            <Nav.Link
                                as={Link}
                                to="/"
                                onClick={handleCloseOffcanvas}
                                className="py-2 px-3 rounded shadow-sm"
                                style={{
                                    background: '#fff',
                                    color: '#555',
                                    fontWeight: '500',
                                    fontSize: '1.2rem',
                                    marginBottom: '0.5rem',
                                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'transform 0.2s, background-color 0.3s'
                                }}
                                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                            >
                                Главная
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/subjects"
                                onClick={handleCloseOffcanvas}
                                className="py-2 px-3 rounded shadow-sm"
                                style={{
                                    background: '#fff',
                                    color: '#555',
                                    fontWeight: '500',
                                    fontSize: '1.2rem',
                                    marginBottom: '0.5rem',
                                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'transform 0.2s, background-color 0.3s'
                                }}
                                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                            >
                                Предметы
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/contacts"
                                onClick={handleCloseOffcanvas}
                                className="py-2 px-3 rounded shadow-sm"
                                style={{
                                    background: '#fff',
                                    color: '#555',
                                    fontWeight: '500',
                                    fontSize: '1.2rem',
                                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'transform 0.2s, background-color 0.3s'
                                }}
                                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                            >
                                Контакты
                            </Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            )}
        </>
    );
};

export default NavBarComponent;
