import React from 'react';
import {Container} from 'react-bootstrap';

const Logo: React.FC = () => {
    return (
        <Container className="text-center my-4">
            <img src="/logo.png" alt="Logo" className="img-fluid" style={{maxWidth: '200px'}}/>
            <h1 className="mt-3">
                Прикладная
                <br/>
                математика и информатика
            </h1>
        </Container>
    );
};

export default Logo;
