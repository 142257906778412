import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import MainPage from "./pages/MainPage";
import NavBarComponent from "./component/NavBarComponent";
import EducationalSubjectsPage from "./pages/EducationalSubjectsPage";
import ContactsPage from "./pages/ContactsPage";
import FunctionalAnalysisPage from "./pages/educationalSubjects/FunctionalAnalysisPage";

const App: React.FC = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userName, setUserName] = useState("");

    const handleLogin = () => {
        setIsAuthenticated(true);
        setUserName("Студент");
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
        setUserName("");
    };

    return (
        <Router>
            <NavBarComponent
                isAuthenticated={isAuthenticated}
                userName={userName}
                handleLogin={handleLogin}
                handleLogout={handleLogout}
            />
            <Routes>
                <Route path="/" element={<MainPage/>}/>
                <Route path="/subjects" element={<EducationalSubjectsPage/>}/>
                <Route path="/contacts" element={<ContactsPage/>}/>

                <Route path="/subjects/functional-analysis" element={<FunctionalAnalysisPage/>}/>
            </Routes>
        </Router>
    );
};

export default App;
