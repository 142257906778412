import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

const EducationalSubjectsPage: React.FC = () => {
    const subjects = [
        {
            id: 1,
            type: 'Экзамен',
            name: 'Функциональный анализ',
            link: "functional-analysis"
        },
        {
            id: 2,
            type: 'Экзамен',
            name: 'Теория вероятностей и математическая статистика',
            link: "/"
        },
        {
            id: 3,
            type: 'Экзамен',
            name: 'Математические методы механики',
            link: "/"
        },
        {
            id: 4,
            type: 'Экзамен',
            name: 'Алгоритмы и теория сложности',
            link: "/"
        },
        {
            id: 5,
            type: 'Экзамен',
            name: 'Уравнения с частными производными',
            link: "/"
        },
        {
            id: 6,
            type: 'Зачёт',
            name: 'Численные методы',
            link: "/"
        },
        {
            id: 7,
            type: 'Зачёт',
            name: 'Основы математического моделирования',
            link: "/"
        },
        {
            id: 8,
            type: 'Зачёт',
            name: 'Программные среды эффективных вычислений',
            link: "/"
        },
        {
            id: 9,
            type: 'Зачёт',
            name: 'Электродинамика и распространение радиоволн',
            link: "/"
        },
    ];

    return (
        <div className="container mt-4">
            <Row className="g-4">
                {subjects.map((subject) => (
                    <Col key={subject.id} xs={12} sm={6} md={4} lg={3} className="mb-4">
                        <Card className="shadow-sm border-light rounded-3 h-100 d-flex flex-column">
                            <Card.Title
                                className="text-center"
                                style={{
                                    fontSize: '1.25rem',
                                    fontWeight: 'bold',
                                    color: '#343A40',
                                    lineHeight: '1.5',
                                    height: '60px',
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingTop: '20px',
                                }}
                            >
                                {subject.name}
                            </Card.Title>
                            <Card.Body className="d-flex flex-column">
                                <Card.Footer
                                    className="mt-auto d-flex justify-content-between align-items-center bg-light"
                                >
                                    <span className="text-muted" style={{fontSize: '0.875rem', fontWeight: '500'}}>
                                        {subject.type}
                                    </span>
                                    <Link
                                        to={`/subjects/${subject.link}`}
                                        className="btn btn-primary btn-sm"
                                        style={{
                                            padding: '6px 12px',
                                            fontSize: '0.875rem',
                                            transition: 'all 0.3s ease',
                                            boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
                                    >
                                        Перейти
                                    </Link>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default EducationalSubjectsPage;