import React from "react";
import Logo from "../component/Logo";
import UpcomingEvents from "../component/UpcomingEvents";

const MainPage: React.FC = (props) => {

    return (
        <div>
            <Logo/>
            <UpcomingEvents/>
        </div>
    )
}

export default MainPage;