import React, { useState, useEffect } from 'react';
import { Button, Card, Container } from 'react-bootstrap';

const UpcomingEvents: React.FC = () => {
    const events = [
        { title: 'РК', details: 'Теория вероятности и математическая статистика', date: '24.10.2024' },
        { title: 'Экзамен', details: 'Функциональный анализ', date: 'No info' },
        { title: 'Экзамен', details: 'Теория вероятностей и математическая статистика', date: 'No info' },
        { title: 'Экзамен', details: 'Математические методы механики', date: 'No info' },
        { title: 'Экзамен', details: 'Алгоритмы и теория сложности', date: 'No info' },
        { title: 'Экзамен', details: 'Уравнения с частными производными', date: 'No info' },
        { title: 'Зачёт', details: 'Численные методы', date: 'No info' },
        { title: 'Зачёт', details: 'Основы математического моделирования', date: 'No info' },
        { title: 'Зачёт', details: 'Программные среды эффективных вычислений', date: 'No info' },
        { title: 'Зачёт', details: 'Электродинамика и распространение радиоволн', date: 'No info' },
    ];

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(3);

    const totalPages = Math.ceil(events.length / itemsPerPage);

    const currentEvents = events.slice(
        currentPage * itemsPerPage,
        currentPage * itemsPerPage + itemsPerPage
    );

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600) {
                setItemsPerPage(2);
            } else {
                setItemsPerPage(3);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Container className="text-center my-4">
            <h2>Предстоящие события</h2>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {currentEvents.map((event, index) => (
                    <Card
                        key={index}
                        style={{
                            width: '100%',
                            marginBottom: '20px',
                            border: '1px solid #e1e1e1',
                            borderRadius: '10px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 5px 15px rgba(0, 0, 0, 0.2)'}
                        onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 2px 5px rgba(0, 0, 0, 0.1)'}
                    >
                        <Card.Body style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <div style={{ flexGrow: 1 }}>
                                <Card.Title style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#333' }}>{event.title}</Card.Title>
                                <Card.Text
                                    style={{
                                        color: '#555',
                                        fontSize: '0.85rem',
                                        marginBottom: '10px',
                                        padding: '10px',
                                        borderLeft: '5px solid #007bff',
                                        borderRadius: '5px',
                                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                        transition: 'box-shadow 0.3s ease-in-out',
                                    }}
                                >
                                    {event.details}
                                </Card.Text>
                            </div>

                            <div style={{
                                fontSize: '0.875rem',
                                color: '#888',
                                marginBottom: '10px',
                                padding: '5px 10px',
                                backgroundColor: '#f0f0f0',
                                borderRadius: '12px',
                                display: 'inline-block',
                                textAlign: 'center',
                                fontWeight: 'bold',
                            }}>
                                <span style={{ color: '#6c757d' }}>Дата: </span>
                                <span style={{ color: '#007bff' }}>{event.date}</span>
                            </div>

                            <Button
                                variant="primary"
                                style={{
                                    padding: '10px 20px',
                                    borderRadius: '20px',
                                    fontSize: '0.875rem',
                                    border: 'none',
                                    backgroundColor: '#007bff',
                                    color: 'white',
                                    boxShadow: '0px 4px 8px rgba(0, 123, 255, 0.4)',
                                    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                                    marginTop: '10px',
                                }}
                                onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    const button = e.target as HTMLButtonElement;
                                    button.style.backgroundColor = '#0056b3';
                                }}
                                onMouseLeave={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    const button = e.target as HTMLButtonElement;
                                    button.style.backgroundColor = '#007bff';
                                }}
                            >
                                Подробнее
                            </Button>
                        </Card.Body>
                    </Card>
                ))}
            </div>

            <div className="mt-3">
                <Button onClick={handlePrev} disabled={currentPage === 0}>
                    Назад
                </Button>
                <span className="mx-3">
                    Страница {currentPage + 1} из {totalPages}
                </span>
                <Button onClick={handleNext} disabled={currentPage === totalPages - 1}>
                    Вперед
                </Button>
            </div>
        </Container>
    );
};

export default UpcomingEvents;
